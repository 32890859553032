export default {
  space: [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  breakpoints: [
    '950px'
  ],
  shadows: {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
  },
  fonts: {
    body: 'Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Bitter, san-serif',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    96
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  text: {
    title: {
      fontSize: [5, 6]
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [5, 6]
    },
    subheading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [4, 5]
    },
    cardBigTitle: {
      fontSize: 4,
      textDecoration: 'none',
      lineHeight: '45px'
    },
    cardTitle: {
      fontSize: [3, 4],
      textDecoration: 'none',
      flex: '0 1 100%',
      maxWidth: '100%'
    },
    cardBigText: {
      fontSize: 'heading',
      textDecoration: 'none',
      color: 'text'
    },
    cardText: {
      fontSize: 'subheading',
      textDecoration: 'none',
      marginTop: 2,
      flex: '0 1 100%',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: 'text'
    },
    cardNumberText: {
      fontSize: [3, 4],
      lineHeight: '45px',
      color: 'myWhite',
      textDecoration: 'none'
    },
    number: {
      fontSize: [4, 7],
      bg: 'secondary',
      color: 'myWhite',
      width: '1em',
      mb: 3,
      fontFamily: 'heading'
    },
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  colors: {
    myWhite: '#F9F9F9',
    text: '#0C1010',
    green: '#2A3938',
    pink: '#ECC7C0',
    yellow: '#D1A827',
    background: '#FFFAFA',
    primary: '#ECC7C0',
    secondary: '#2A3938',
    muted: '#D1A827',
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [5, 6]
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 'subheading'
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      margin: 4
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
      '&:visited': {
        color: 'primary'
      }
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    }
  },
  variants: {
    cardLink: {
      textDecoration: 'none',
      flex: ['0 1 100%', '0 1 48%'],
      maxWidth: ['100%', '48%'],
    },
    cardBlogContainer: {
      flex: ['0 1 100%', '0 1 30%'],
      maxWidth: ['100%', '30%']
    },
    cardBlogLink: {
      textDecoration: 'none',
    },
    cardProjectLink: {
      textDecoration: 'none',
      flex: ['0 1 100%', '0 1 48%'],
      maxWidth: ['100%', '48%'],
    },
    cardImage: {
      boxShadow: '2px 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      bg: 'background',
      display: 'flex',
      flexWrap: "wrap",
      marginTop: 3,
      marginBottom: 3
    },
    imageCard: {
      height: "250px",
      width: "100%",
      bg: "white",
      objectFit: "cover"
    },
    cardCompact: {
      padding: 4,
      boxShadow: '2px 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      bg: 'background',
      display: 'flex',
      flexWrap: "wrap",
      marginTop: 3,
      marginBottom: 3
    },
    cardNumberBox: {
      bg: 'green',
      height: '45px',
      width: '45px',
      textAlign: "center",
      mr: 2
    },
    nav: {
      fontWeight: 'bold',
      color: 'primary',
      textDecoration: 'none',
      fontSize: 4,
      fontFamily: 'Bitter'
    },
    buttonSecondary: {
      bg: 'primary',
      py: 2,
      px: 3,
      my: 2,
      borderRadius: '5px',
      color: 'white',
      textDecoration: 'none',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary',
      '&:hover': {
        bg: 'white',
        color: 'primary'
      }
    }
  },
  buttonPrimary: {
    bg: 'secondary',
    py: 2,
    px: 3,
    my: 2,
    borderRadius: '5px',
    color: 'white',
    textDecoration: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'secondary',
    '&:hover': {
      bg: 'transparent',
      color: 'secondary'
    }
  }
}